import React, {useState, useEffect} from 'react';
import {createContext} from '@deezer/react-utils';
import Captcha from '@deezer/recaptcha';

type ReCaptchaContext = {
	reCaptcha: Captcha;
	abortHandler: (setLoading: (arg0: boolean) => void) => void;
};
const [ReCaptchaContextProvider, useReCaptcha] =
	createContext<ReCaptchaContext>({
		name: 'ReCaptcha',
	});

export {useReCaptcha};
export class RecaptchaError extends Error {
	payload: unknown;
	constructor(payload: unknown) {
		super('recaptcha_error');
		this.payload = payload;
		this.name = 'RecaptchaError';
	}
}

export const ReCaptcha: React.FC<{
	apiKey: string;
	children: React.ReactNode;
	containerId?: string;
}> = ({apiKey, children, containerId = 'captcha-id'}) => {
	const [reCaptcha] = useState(() => new Captcha({apiKey}));

	const abortHandler = (setLoading: (value: boolean) => void) => {
		const recaptchaModal = document.querySelector(
			'iframe[src*="google.com/recaptcha/enterprise/bframe"]',
		);
		const abort = (event: MouseEvent) => {
			if (!recaptchaModal?.contains(event.target as Node)) {
				removeEventListener('click', abort);
				setLoading(false);
			}
		};
		addEventListener('click', abort);
	};

	useEffect(() => {
		reCaptcha.init({containerId});
		return () => {
			try {
				reCaptcha.reset();
			} catch {
				// Don't fail if recaptcha hasn't had the time to init
			}
		};
	}, [reCaptcha, containerId]);

	return (
		<ReCaptchaContextProvider value={{reCaptcha, abortHandler}}>
			<div id={containerId}></div>
			{children}
		</ReCaptchaContextProvider>
	);
};
