import {useAppState} from '@app/modules/apps/context';
import {useCallback, useEffect} from 'react';
import {
	useRedirection,
	useRedirectionAfterAuth,
} from '@app/modules/redirection/hooks';
import {useAuthConfig} from '../auth/useAuthConfig';
import {useLogAuthConversionStart} from '../metrics/useAuthRateMetrics';
import {useAutolog} from '@app/hooks/auth/useAutolog';
import {AuthStatus} from '@app/hooks/auth/useAutolog/utils';
import {useUserData} from './useUserData';
import type {AuthResult} from '../auth/types';
import {setClientCookie} from '@app/utils/cookies';
import {useAuthVitalsMetrics} from '../metrics/useAuthVitalsMetrics';

export const useUnloggedController = () => {
	const {status} = useAutolog();
	const redirect = useRedirection();

	useEffect(() => {
		if (status === AuthStatus.logged) {
			redirect();
		}
	}, [redirect, status]);

	return {
		isLoading: status === AuthStatus.loading,
	};
};

export function useAuthController(afterAuthSteps?: string[]) {
	const {status, mutate} = useAutolog();
	const {refetchUser} = useUserData();
	const authConfig = useAuthConfig();
	const {appName} = useAppState();
	const {logAuthError} = useAuthVitalsMetrics();
	const redirectAfterAuth = useRedirectionAfterAuth();
	useLogAuthConversionStart();

	const onAuthSuccess = useCallback(
		async (tokens?: AuthResult) => {
			let displayPicker = false;
			try {
				const user = await refetchUser();
				if (!user?.id) return;

				if (appName === 'Zen') {
					setClientCookie(
						'isSubProfile',
						user.familyStatus.parent && !user.familyStatus.isSubAccount
							? 'true'
							: 'false',
					);
				}

				// Display picker -> family selection activated for the app + family member connected
				displayPicker =
					user?.familyStatus.isActive &&
					user?.familyStatus.isEnabled &&
					user?.familyStatus.childCount > 0 &&
					!user?.familyStatus.isSubAccount;
			} catch (error: any) {
				logAuthError({
					e: {
						name: 'PostAuthError',
						message: 'post auth getUserData has failed',
					},
					journey_type: 'common',
					gateway_method: 'user.getData',
					payload: error,
				});
			} finally {
				if (tokens) {
					await mutate({
						status: AuthStatus.logged,
						data: {...tokens, user_id: parseInt(tokens.user_id, 10)},
					});
				}
				redirectAfterAuth(afterAuthSteps, displayPicker);
			}
		},
		[
			afterAuthSteps,
			appName,
			mutate,
			redirectAfterAuth,
			refetchUser,
			logAuthError,
		],
	);

	useEffect(() => {
		// Return early if the user is not logged in or if the fetch is already complete
		if (!(status === AuthStatus.logged)) return;
		onAuthSuccess();
	}, [onAuthSuccess, status]);

	return {
		authConfig,
		isLoading: !authConfig.data || status !== AuthStatus.unlogged,
		authCallback: onAuthSuccess,
	};
}
